<template>
  <table-view ref="tableView"
              url="/car/listForPage"
              :filterable="false"
              :filter-form="dataForm"
              :downloadable="isAuthed('car_info_download')"
              download-url="/car/download/template"
              download-file-name="车辆信息模板"
              :uploadable="isAuthed('car_info_import')"
              upload-title="批量上传车辆信息"
              upload-url="/car/batch"
              :upload-columns="uploadColumns"
              :batch-removable="isAuthed('car_info_delete')"
              delete-url="/car/deleteById"
              :exportable="isAuthed('car_info_export')"
              :viewable=true
              export-url="/car/download"
              export-file-name="车辆信息表"
              :recovered="isAuthed('car_info_paused')"
              recovered-url="/car/recovered"
              :paused="isAuthed('car_info_paused')"
              paused-url="/car/paused"
              :auditable="isAuthed('car_info_approve')"
              :addable="isAuthed('car_info_add')"
              :editable="isAuthed('car_info_update')"
              :headers="headers"
              show-index
              selectable
              single>
    <el-form slot="search"
             @keyup.enter.native="query">
      <el-input v-model="dataForm.number"
                clearable
                placeholder="自编号"></el-input>
      <el-input v-model="dataForm.licensePlate"
                clearable
                placeholder="车牌号码"></el-input>
      <!-- <el-input v-model="dataForm.simCardNumber" clearable placeholder="sim卡号"></el-input> -->
      <el-select v-model="dataForm.companyId"
                 clearable
                 filterable
                 placeholder="所属公司">
        <el-option v-for="item in companyList"
                   :key="item.id"
                   :label="item.companyName"
                   :value="item.id"></el-option>
      </el-select>
      <el-select v-model="dataForm.carStatus"
                 clearable
                 filterable
                 placeholder="状态">
        <el-option v-for="item in statusList"
                   :key="item.dictId"
                   :label="item.dictName"
                   :value="item.dictId"></el-option>
      </el-select>
      <div style="display: flex; align-items: center;padding-right: 10px;padding-top: 5px;">
        <el-button size="mini"
                   @click="query"
                   type="primary">查询</el-button>
      </div>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'car-car',

  components: { TableView },

  data() {
    return {
      headers: [
        { id: 1, prop: 'number', label: '自编号', width: 120 },
        { id: 2, prop: 'licensePlate', label: '车牌号码', width: 120 },
        { id: 5, prop: 'carStatus', label: '状态', width: 80 },
        { id: 6, prop: 'carSize', label: '车辆类别', width: 100 },
        { id: 7, prop: 'chassis', label: '底盘', width: 100 },
        { id: 8, prop: 'startupDate', label: '启用日期', width: 100 },
        { id: 9, prop: 'companyName', label: '所属公司', width: 150 },
        {
          id: 10,
          prop: 'commercialInsuranceDate',
          label: '商业险到期日',
          width: 100,
        },
        {
          id: 11,
          prop: 'trafficInsuranceDate',
          label: '交强险到期日',
          width: 100,
        },
        {
          id: 12,
          prop: 'nextInspectionDate',
          label: '车辆检测到期日',
          width: 120,
        },
        { id: 13, prop: 'emissionStandard', label: '排放标准', width: 150 },
        { id: 14, prop: 'baseName', label: '所属基地', width: 180 },
        { id: 3, prop: 'carDeviceId', label: '终端号', minWidth: 120 },
      ],
      uploadColumns: [
        { id: 1, prop: 'number', label: '自编号', width: 120 },
        { id: 2, prop: 'licensePlate', label: '车牌号码', width: 120 },
        { id: 3, prop: 'carDeviceId', label: 'sim卡号', minWidth: 120 },
        { id: 4, prop: 'carSize', label: '车辆类型', width: 100 },
        { id: 5, prop: 'chassis', label: '底盘', width: 100 },
        { id: 6, prop: 'startupDate', label: '启用日期', width: 100 },
        { id: 7, prop: 'companyName', label: '所属公司', width: 150 },
        { id: 8, prop: 'baseName', label: '所属基地', width: 180 },
        { id: 9, prop: 'emissionStandard', label: '排放标准', width: 180 },
      ],
      dataForm: {
        number: null,
        licensePlate: null,
        simCardNumber: null,
        companyId: null,
        carStatus: null,
      },
      companyList: [],
      statusList: [],
    }
  },

  methods: {
    query() {
      this.$refs.tableView.queryData()
    },
  },

  watch: {
    dataForm: {
      handler(v) {
        sessionStorage.setItem('car-car-dataForm', JSON.stringify(v))
      },
      deep: true,
    },
  },

  async created() {
    let _dataForm = sessionStorage.getItem('car-car-dataForm')
    if (_dataForm) {
      this.dataForm = Object.assign(this.dataForm, JSON.parse(_dataForm))
    }
    let data = await this.$http({
      url: this.$http.adornUrl('/company/list'),
      method: 'post',
      data: {},
    })
    this.companyList = data.datas
    data = await this.$http({
      url: this.$http.adornUrl('/common/carStatusList'),
      method: 'post',
    })
    this.statusList = data.datas
  },
}
</script>
